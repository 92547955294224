<template>
	<nav class="navbar is-dark is-mobile" style="background: rgb(65 75 77); color:#ffffff!important;">
		<div class="is-flex">
			<div class="navbar-start ">
				<router-link to="/" class="navbar-item py-1 has-text-white uppercase">
					<span class="text-sky-200 font-bold">
						{{configs.company_name}}
					</span>
				</router-link>
			</div>
			<a @click.prevent="show_menu_mobile = !show_menu_mobile" role="button" class="navbar-burger py-1" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"
				style="height:35px;">
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
			</a>
		</div>
		<div id="navbarBasicExample"
			:class="{'mobile-menu' : show_menu_mobile}"
			class="navbar-menu is-mobile">
			<div class="navbar-end" v-if="user_store.session_check == 1">
				<router-link to="/login" class="navbar-item py-1 has-text-right" v-if="!user_store.is_loggedin">
					Login
				</router-link>
				
				<div v-if="user_store.is_loggedin && am_allowed('menu_roles.can_see_menu_search')" 
					@click.prevent="start_open_search_page()"
					class="navbar-item py-1 has-text-right bg-red-700 relative cursor-pointer">
					<i class="fa-solid fa-magnifying-glass"></i>
				</div>

				<div v-if="user_store.is_loggedin" 
					@click.prevent="open_notifications_list()  "
					class="navbar-item py-1 has-text-right bg-sky-700 relative cursor-pointer">
					<i class="fa-solid fa-bell mr-2"></i> {{menu_store.notification_count}}
					<div v-if="menu_store.show_menu_notifications" 
						v-click-outside="click_outside_notifications()"
						class="notification-list-wrap py-2 border rounded bg-white text-black w-64">
						<div v-if="menu_store.visible_notifications.length > 0">
							<div v-for="notification, index in menu_store.visible_notifications" :key="index"
								@click="open_notification(notification)"
								class="border-b flex flex-col px-2 py-1 hover:bg-sky-100">
								<div class="flex justify-between border-b border-sky-50 text-14px mb-1">
									<span class="text-sky-700 ">{{notification.type == 'discount_request' ? 'Kerkese per zbritje' : ''}}</span>
									<span class="text-slate-500 ">{{format_date(notification.requested_at)}}</span>
								</div>
								<div class="flex flex-col text-left text-14px">
									<span class="text-slate-500">
										<i class="fa-solid fa-user mr-2 text-slate-400 text-12px"></i>{{notification.requested_by_name}}
									</span>
									<span class="text-slate-500">
										<i class="fa-regular fa-circle-user mr-2 text-slate-400 text-12px"></i>{{notification.customer_name}}
									</span>
									<span class="text-slate-500">
										<i class="fa fa-store mr-2 text-slate-400 text-12px"></i>{{notification.pos_name}}
									</span>
								</div>
							</div>
						</div>
						<div v-else>
							<p class="text-center text-14px text-slate-400">
								Nuk ka njoftime te reja
							</p>
						</div>
					</div>
				</div>
				<router-link v-if="user_store.is_loggedin" to="/profile" class="navbar-item py-1 has-text-right">
					<i class="fa-solid fa-user mr-2"></i> {{user_store.user.name}}
				</router-link>
				<router-link to="/help" class="navbar-item py-1 has-text-right">
					Help
				</router-link>
				<a class="navbar-item py-1 has-text-right" @click.prevent="logout" v-if="user_store.is_loggedin">Logout</a>
			</div>
			<div class="navbar-end" v-if="user_store.session_check == -1">
				<div class="navbar-item py-1 has-text-info">
					<i class="fa-solid fa-spinner fa-spin"></i>
				</div>
			</div>
		</div>
	</nav>
	<Cookies v-if="!has_cookies"></Cookies>
</template>

<script>
import Api from '@/services/Api'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useMenuStore } from '@/stores/menu'
import { defineAsyncComponent } from 'vue'
import { useConfigurationsStore } from '@/stores/configurations'
import permissionMixin from '@/mixins/permissions-mixin.js'
// import { useRoute } from 'vue-router'
import moment from "moment";

	export default{
		mixins: [permissionMixin],
		components: {
			Cookies: defineAsyncComponent( () => import('@/components/Cookies.vue') )
		},
		data(){
			return {
				user_store: storeToRefs(useUserStore()),
				menu_store: storeToRefs(useMenuStore()),
				
				show_menu_mobile: false, 
				configs: storeToRefs(useConfigurationsStore())
			}
		},
		computed: {
			has_cookies() {
				return localStorage.cookies;
			},
		},
		created(){
		},
		methods: {
			clearLocalStorage(){
				localStorage.removeItem("user")
				localStorage.removeItem("token")
				localStorage.removeItem("user_roles")
				localStorage.removeItem("permissions")
				// localStorage.removeItem("hiden_columns")
			},
			logout(){
				Api(true).post("user/logout", {})
					.finally(() => {
						this.clearLocalStorage()
						this.$router.push({path: "/login"})
						this.user_store.is_loggedin = false
						this.user_store.user = {}
					})
			},
			open_notifications_list(){
				useMenuStore().open_notifications_list()
			},
			start_open_search_page(){
				this.$router.push({name: 'Kerko produkte', path: "/search_page"})
			},
			format_date(date) {
				return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:ss");
			},
			open_notification(notification){
				if(notification.type == 'discount_request'){
					this.$router.push({ name: 'Konfirmo zbritje', params: { id: notification.discount_request_id } })
				}
			},
			click_outside_notifications(){
				if(this.menu_store.show_menu_notifications){
					// this.menu_store.show_menu_notifications = false
				}
			}
		}
	}
</script>

<style scoped>

.mobile-menu{
	display: block!important;
	color:#fff!important;
	background: #646f9b;
}
#navbarBasicExample .navbar-end .navbar-item{
	color:#ffffff;
}

.notification-list-wrap{
	position: absolute; 
	top: 35px; 
	left: 0; 
	z-index:99; 
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
	overflow-y: auto; 
	max-height: 400px;
}

</style>

